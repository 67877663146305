import React from "react";
import {Text, View, StyleSheet,TouchableOpacity} from 'react-native';
import { Icon } from "react-native-elements";
import { styles } from "../assets/Style";

export default function HeaderBase({navigation}){
    return (
        <View style={[styles.LogoBar]}>
        <View style={{alignSelf: "flex-start", width:50}} ></View>
        <View style={{flex: 1, alignItems: 'center'}}>
            <Text style={styles.LogoFont}>Befriender</Text>
        </View>
        <View style={{alignSelf: "flex-end", width:50}} ></View>
    </View>
    )
}