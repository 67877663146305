import React, { useContext, useState } from "react";
import {
  View,
  Text,
  Platform,
  KeyboardAvoidingView,
  Image,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Alert,
} from "react-native";
import { styles } from "../assets/Style";
import Feather from "react-native-vector-icons/Feather";
import { updateEmail, verifyEmail } from "../bapi";
import { useNavigation, useRoute } from "@react-navigation/native";
import Context from "../Context/context";

const EmailChangeScreen = ({}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const context = useContext(Context);
  const backRoute = route.params.backRoute;
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [pin, setPin] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(0); // 0: Enter current and new email, 1: Enter PIN

  const handleSendPinEmail = async () => {
    if (step === 0) {
      if (currentEmail.length === 0 || newEmail.length === 0) {
        setError("Please enter both current and new email.");
        return;
      }

      if (!isValidEmail(newEmail)) {
        setError("Invalid new email.");
        return;
      }

      // Send a PIN email to the new email address
      const response = await updateEmail(context.token, currentEmail, newEmail);

      if (response.error) {
        setError(response.message);
      } else {
        setError(""); // Clear any previous errors
        setStep(1); // Proceed to the next step (entering PIN)
      }
    } else if (step === 1) {
      if (pin.length === 0) {
        setError("Please enter the PIN from the email.");
        return;
      }

      // Verify the email change using the PIN
      const verificationResponse = await verifyEmail(
        context.token,
        currentEmail,
        newEmail,
        pin
      );

      if (verificationResponse.error) {
        setError(verificationResponse.message);
      } else {
        setError(""); // Clear any previous errors
        Alert.alert("Success", "Email address changed successfully.");
        // You can navigate back to the previous screen or perform other actions as needed
        handleBack();
      }
    }
  };

  const isValidEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleBack = () => {
    setCurrentEmail("");
    setNewEmail("");
    setPin("");
    setError("");
    setStep(0);
    navigation.navigate(backRoute);
  };

  const renderStepOne = () => (
    <View style={styles.panel}>
      <Image
        style={styles.logoImage}
        source={require("../assets/images/BFcircle.png")}
      />
      {error ? <Text style={styles.errorText}>{error}</Text> : null}
      <Text style={[styles.directionText, styles.question]}>
        Enter your current and new email:
      </Text>
      <Text style={styles.textlabel}>Current Email</Text>
      <TextInput
        style={styles.textinput}
        placeholder="Enter Current Email"
        value={currentEmail}
        onChangeText={setCurrentEmail}
      />
      <Text style={styles.textlabel}>New Email</Text>
      <TextInput
        style={styles.textinput}
        placeholder="Enter New Email"
        value={newEmail}
        onChangeText={setNewEmail}
      />
      <View style={styles.buttonbar}>
        <TouchableOpacity
          style={[styles.button, styles.buttonyellow]}
          onPress={() => handleBack()}
        >
          <Text style={styles.text}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.buttonpink]}
          onPress={handleSendPinEmail}
        >
          <Text style={styles.text}>Send PIN Email</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  const renderStepTwo = () => (
    <View style={styles.panel}>
      <Image
        style={styles.logoImage}
        source={require("../assets/images/BFcircle.png")}
      />
      {error ? <Text style={styles.errorText}>{error}</Text> : null}
      <Text style={[styles.directionText, styles.question]}>
        Enter the PIN from the email:
      </Text>
      <Text style={styles.textlabel}>PIN</Text>
      <TextInput
        style={styles.textinput}
        placeholder="Enter PIN"
        value={pin}
        onChangeText={setPin}
      />
      <View style={styles.buttonbar}>
        <TouchableOpacity
          style={[styles.button, styles.buttonyellow]}
          onPress={() => setStep(0)}
        >
          <Text style={styles.text}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.buttonpink]}
          onPress={handleSendPinEmail}
        >
          <Text style={styles.text}>Confirm Change</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  let renderStep = null;
  if (step === 0) {
    renderStep = renderStepOne;
  } else if (step === 1) {
    renderStep = renderStepTwo;
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={100}
      enabled
      style={styles.Bodyscroll}
    >
      <ScrollView>{renderStep && renderStep()}</ScrollView>
    </KeyboardAvoidingView>
  );
};

export default EmailChangeScreen;
