import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  Modal,
} from "react-native";
import { styles } from "../assets/Style";
import { useNavigation, useRoute } from "@react-navigation/native";
import { Ionicons } from "@expo/vector-icons"; // Import Ionicons from Expo
// Sample game data
// const gamesData = [
//   {
//     id: 1,
//     title: "Game 1",
//     icon: require("../assets/images/BFcircle150.png"),
//     description: "Description of Game 1",
//   },
//   {
//     id: 2,
//     title: "Game 2",
//     icon: require("../assets/images/BFcircle150.png"),
//     description: "Description of Game 2",
//   },
//   {
//     id: 3,
//     title: "Game 3",
//     icon: require("../assets/images/BFcircle150.png"),
//     description: "Description of Game 3",
//   },
//   // Add more games as needed
// ];
const gamesData = [
  {
    id: 1,
    title: "Game Time Fun!",
    icon: require("../assets/images/BFcircle150.png"),
    description:
      "Get ready for some exciting chat games coming soon. \n Challenge your friends and have a blast together!",
  },
  // Add more games as needed
];

const GamesModal = ({ isVisible, toggleModal }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const groupToken = route.params.groupToken;
  //   const groupAlias = route.params.alias;
  //   const groupAvatar = route.params.avatar;
  //   const backRoute = route.params.backRoute;

  const handlePlayGame = (gameId) => {
    // Logic to handle playing the selected game
    // Replace with your implementation
    console.log("Playing game with ID:", gameId);
  };

  return (
    <Modal
      visible={isVisible}
      animationType="slide"
      transparent={true}
      onRequestClose={() => setShowInfoModal(false)}
    >
      <View style={styles.modalPanel}>
        <View style={styles.Header}>
          <Text style={styles.HeaderFont}>Games</Text>
        </View>
        <ScrollView>
          {gamesData.map((game) => (
            <View style={styles.MsgContainer} key={game.id}>
              <Image
                source={game.icon}
                style={[styles.avatarIcon, { marginRight: 8, marginLeft: 8 }]}
              />
              <View style={styles.question}>
                <Text style={styles.text}>{game.title}</Text>
                <Text style={styles.MsgTag}>{game.description}</Text>
              </View>
              <TouchableOpacity
                style={[
                  styles.gamesButtonContainer,
                  styles.button,
                  styles.buttonblue,
                  { alignSelf: "end" },
                ]}
                // onPress={() => handlePlayGame(game.id)}
                // onPress={() => navigation.navigate("Games")}
              >
                <Text style={styles.textwhite}>Play</Text>
              </TouchableOpacity>
            </View>
          ))}
        </ScrollView>
        <View style={styles.hr}></View>
        <View style={styles.buttonbar}>
          <TouchableOpacity
            style={[styles.button, styles.buttonpink]}
            onPress={toggleModal}
          >
            <Text style={styles.text}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default GamesModal;
