import React, { useState } from "react";
import {
  View,
  Text,
  Platform,
  KeyboardAvoidingView,
  Image,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Alert,
} from "react-native";
import { styles } from "../assets/Style";
import Feather from "react-native-vector-icons/Feather";
import { signUp, verifyPin } from "../bapi";
import { AVATAR_URL } from "../constants"; // Import Avatar URL

const SignUpScreen = ({ navigation }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPIN] = useState("");
  const [password, setPassword] = useState("");
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [confirmSecureTextEntry, setConfirmSecureTextEntry] = useState(true);
  const [error, setError] = useState("");
  const [step, setStep] = useState(0); // 0: Enter display name and email, 1: Enter PIN and password

  const handleNext = () => {
    if (step === 0) {
      if (username.length === 0 || email.length === 0) {
        setError("Please enter display name and email.");
        return;
      }

      if (!isValidEmail(email)) {
        setError("Invalid email.");
        return;
      }

      InsertRecord();
    } else if (step === 1) {
      if (pin.length === 0 || password.length === 0) {
        setError("Please enter PIN and password.");
        return;
      }

      // Implement user sign-up process here
      UpdateRecord();
    }
  };

  const isValidEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleBack = () => {
    setUsername("");
    setEmail("");
    setPIN("");
    setPassword("");
    setError("");
    setStep(0);
    navigation.navigate("Login");
  };

  const handleStep = () => {
    setError("");
    setStep(0);
  };

  const UpdateRecord = async () => {
    try {
      const data = await verifyPin(username, email, pin, password);
      if (data.error) {
        setError(data.message);
      } else {
        setError(""); // Clear any previous errors
        handleBack();
      }
    } catch (error) {
      Alert.alert("Error", error.message);
    }
  };

  const InsertRecord = async () => {
    if (username.length === 0 || email.length === 0) {
      setError("Please enter display name and email.");
      return;
    }

    if (!isValidEmail(email)) {
      setError("Invalid email.");
      return;
    }

    try {
      const data = await signUp(username, email);
      if (data.error) {
        setError(data.message);
      } else {
        setError(""); // Clear any previous errors
        setStep(1);
      }
    } catch (error) {
      Alert.alert("Error", error.message);
    }
  };

  const updateSecureTextEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  const updateConfirmSecureTextEntry = () => {
    setConfirmSecureTextEntry(!confirmSecureTextEntry);
  };

  const renderStepOne = () => (
    <View style={styles.panel}>
      <Image
        style={styles.logoImage}
        source={require("../assets/images/BFcircle.png")}
      />
      {error ? <Text style={styles.errorText}>{error}</Text> : null}
      <Text style={[styles.directionText, styles.question]}>
        Enter your display name and email:
      </Text>
      <Text style={styles.textlabel}>Display Name</Text>
      <View style={styles.row}>
        <TextInput
          style={styles.textinput}
          placeholder="Enter Display Name"
          value={username}
          onChangeText={setUsername}
        />
      </View>
      <Text style={styles.textlabel}>Email</Text>
      <View style={styles.row}>
        <TextInput
          style={styles.textinput}
          placeholder="Enter Email Address"
          value={email}
          onChangeText={setEmail}
        />
      </View>
      <View style={styles.buttonbar}>
        <TouchableOpacity
          style={[styles.button, styles.buttonyellow]}
          onPress={handleBack}
        >
          <Text style={styles.text}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.buttonpink]}
          onPress={handleNext}
        >
          <Text style={styles.text}>Send PIN Email</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
  var AvatarURL = `${AVATAR_URL}` + "B_0_0_0_0_0.png";
  const renderStepTwo = () => (
    <View style={styles.panel}>
      <TouchableOpacity style={{ alignItems: "center" }}>
        <Image
          source={{
            uri: AvatarURL,
          }}
          style={styles.avatarOutline}
        />
        <View style={styles.avatarTag}>
          <Text style={styles.textwhite}>{username}</Text>
        </View>
      </TouchableOpacity>
      {error ? <Text style={styles.errorText}>{error}</Text> : null}
      <Text style={[styles.directionText, styles.question]}>
        Enter the PIN and password for your account:
      </Text>
      <Text style={styles.textlabel}>PIN</Text>
      <View style={styles.row}>
        <TextInput
          style={styles.textinput}
          placeholder="Enter PIN"
          value={pin}
          onChangeText={setPIN}
        />
      </View>
      <Text style={styles.textlabel}>Password</Text>
      <View style={styles.row}>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.textinput}
            placeholder="Password"
            secureTextEntry={secureTextEntry}
            value={password}
            onChangeText={setPassword}
          />
          <TouchableOpacity
            style={styles.eyeIcon}
            onPress={updateSecureTextEntry}
          >
            {secureTextEntry ? (
              <Feather name="eye-off" color="grey" size={20} />
            ) : (
              <Feather name="eye" color="black" size={20} />
            )}
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.question}>
        <Text style={styles.text}>
          By creating an account you agree to our Terms and Privacy.
        </Text>
      </View>
      <View style={styles.buttonbar}>
        <TouchableOpacity
          style={[styles.button, styles.buttonyellow]}
          onPress={handleStep}
        >
          <Text style={styles.text}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.buttonpink]}
          onPress={handleNext}
        >
          <Text style={styles.text}>Sign Up</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  let renderStep = null;
  if (step === 0) {
    renderStep = renderStepOne;
  } else if (step === 1) {
    renderStep = renderStepTwo;
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={100}
      enabled
      style={styles.Bodyscroll}
    >
      <ScrollView>{renderStep && renderStep()}</ScrollView>
    </KeyboardAvoidingView>
  );
};

export default SignUpScreen;
