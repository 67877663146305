import React, { useEffect, useContext } from "react";
import { Text, View, StyleSheet, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import { styles } from "../assets/Style";
import Context from "../Context/context";

export default function Header({ navigation }) {
  const context = useContext(Context);

  if (context.noteFriends || context.noteGroup || !context.QA) {
    if (context.noteHeader !== 1) {
      context.setNoteHeader(1);
    }
  } else {
    if (context.noteHeader !== 0) {
      context.setNoteHeader(0);
    }
  }

  return (
    <View style={[styles.LogoBar]}>
      <TouchableOpacity
        style={{ alignSelf: "flex-start" }}
        onPress={() => navigation.toggleDrawer()}
      >
        <View>
          <Icon name="menu" size={50} color="#fff" />
          {context.noteHeader !== 0 && context.noteHeader && (
            <View style={styles.notificationIndicator}>
              <Text style={styles.notificationText}>!</Text>
            </View>
          )}
        </View>
      </TouchableOpacity>
      <View style={{ flex: 1, alignItems: "center" }}>
        <Text style={styles.LogoFont} onPress={() => navigation.toggleDrawer()}>
          Befriender
        </Text>
      </View>
      <View style={{ alignSelf: "flex-end", width: 50 }}></View>
    </View>
  );
}
