import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Modal,
} from "react-native";
import { styles } from "../assets/Style";
import Context from "../Context/context";
import { AVATAR_URL } from "../constants";
import { getGroupAvatars, getGroupExpire, requestGroup } from "../bapi";
import GroupScreen from "../components/GroupScreen";
import {
  useFocusEffect,
  useNavigation,
  useRoute,
} from "@react-navigation/native";
import RemainingClock from "../components/RemainingTime"; // Import the RemainingTime component

const HomeScreen = () => {
  const context = useContext(Context);
  const navigation = useNavigation();
  const route = useRoute();
  const [groupAvatars, setGroupAvatars] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [groupChatToken, setGroupChat] = useState("");
  const [groupChatNote, setGroupChatNote] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false); // Add a state for the info modal

  useFocusEffect(
    React.useCallback(() => {
      setLoading(true);
      fetchGroupExpiryDate();
      setLoading(false);
    }, [route.params.refresh])
  );

  const fetchAvatars = async () => {
    try {
      if (context.token === "" || remainingTime === 0) return;
      const response = await getGroupAvatars(context.token);
      if (response.error) {
        throw new Error(response.message);
      }
      const groupMembers = response.groupMembers;

      const hasNote = groupMembers.some((member) => member.Note === 1);

      const avatars = groupMembers.map((member) => ({
        avatar: member.Avatar,
        alias: member.Alias,
        token: member.GroupToken,
        user_status: "none",
        user_banned: member.user_banned,
        note: member.Note,
      }));
      if (hasNote) {
        if (context.noteGroup !== 1) {
          context.setNoteGroup(1);
        }
      }
      setGroupAvatars(avatars);
    } catch (error) {
      console.error("Error fetching avatars:", error);
    }
  };

  const fetchGroupExpiryDate = async () => {
    try {
      context.setNoteGroup(0);
      if (context.token === "") return;
      const response = await getGroupExpire(context.token);
      if (response.error) {
        console.error(response.message);
      }
      const groupTime = response.RemainingTime;

      if (groupTime > 0) {
        setRemainingTime(groupTime);
        setGroupChat(response.GroupToken);
        setGroupChatNote(response.Note);
        if (response.Note) {
          context.setNoteGroup(response.Note);
        }
        fetchAvatars();
      } else {
        setRemainingTime(groupTime);
      }
    } catch (error) {
      console.error("Error fetching group expiry date:", error);
    }
  };

  const handleGroupMatch = async () => {
    setLoading(true);
    try {
      if (context.token === "") return;
      const response = await requestGroup(context.token);
      if (response.error) {
        console.error(response.message);
      }
      fetchGroupExpiryDate();
    } catch (error) {
      console.error("Error requesting group:", error);
    }
    setLoading(false);
  };

  const handleGroupChat = (groupToken, alias) => {
    navigation.navigate("Chat", {
      groupToken,
      alias,
      avatar: "",
      backRoute: route.name,
    });
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#F7F6CF" />
      </View>
    );
  }

  return (
    <ScrollView style={styles.Bodyscroll}>
      <View style={styles.panel}>
        <View style={[styles.Header, styles.headerTab]}>
          <Text style={styles.HeaderFont}>Friendship Hub</Text>
          <TouchableOpacity
            style={styles.HeaderInfo}
            onPress={() => setShowInfoModal(true)}
          >
            <Text style={styles.HeaderInfoText}>?</Text>
          </TouchableOpacity>
        </View>
        <RemainingClock remainingTime={remainingTime} />
        <View style={styles.hr}></View>
        {remainingTime ? (
          <>
            <View>
              <TouchableOpacity
                style={styles.answers}
                onPress={() => handleGroupChat(groupChatToken, "Group Chat")}
              >
                <Text style={styles.text}>Group Chat</Text>
              </TouchableOpacity>
              {groupChatNote !== 0 && groupChatNote && (
                <View style={styles.notificationIndicator}>
                  <Text style={styles.notificationText}>!</Text>
                </View>
              )}
            </View>
            <View style={styles.groupContainer}>
              <GroupScreen
                navigation={navigation}
                groupAvatars={groupAvatars}
              />
            </View>
          </>
        ) : (
          <TouchableOpacity onPress={() => handleGroupMatch()}>
            <Image
              style={styles.logoImage}
              source={require("../assets/images/BFcircle.png")}
            />
          </TouchableOpacity>
        )}
      </View>

      {/* Information Modal */}
      <Modal
        visible={showInfoModal}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setShowInfoModal(false)}
      >
        <View style={styles.modalPanel}>
          <View style={styles.Header}>
            <Text style={styles.HeaderFont}>
              Welcome to the Friendship Hub!
            </Text>
            {/* <TouchableOpacity
              style={styles.HeaderInfo}
              onPress={() => setShowInfoModal(false)}
            >
              <Text style={styles.HeaderInfoText}>X</Text>
            </TouchableOpacity> */}
          </View>
          <ScrollView style={styles.modalContent}>
            <Text style={styles.text}>
              Welcome to the Friendship Hub, a place where meaningful
              connections blossom! 🌼{"\n"}
              {"\n"}
            </Text>
            <Text style={styles.text}>
              Here's a quick rundown of what this hub is all about:{"\n"}
            </Text>
            <Text style={styles.h3}>👥 Group of 9 Users:</Text>
            <Text style={styles.text}>
              You're part of a close-knit group, comprising yourself and eight
              other fantastic individuals. We've gathered you all here for a
              week of shared experiences.
            </Text>
            <Text style={styles.h3}>⏳ Time Remaining Counter:</Text>
            <Text style={styles.text}>
              In the upper corner, you'll find a countdown timer. It shows you
              how much time you have left in this group. Make the most of it!
            </Text>
            <Text style={styles.h3}>😊 💬 Group and Direct Chats:</Text>
            <Text style={styles.text}>
              You have the flexibility to chat with the entire group or have
              one-on-one conversations with anyone here. Feel free to share
              stories, advice, or just chat about your day.
            </Text>
            <Text style={styles.h3}>💖 Friendship Building:</Text>
            <Text style={styles.text}>
              If you connect with someone in the group, you can easily add them
              to your friends list. It's a fantastic way to continue your
              friendship beyond the hub.
            </Text>
            <Text style={styles.h3}>🔄 New Groups Weekly:</Text>
            <Text style={styles.text}>
              When the timer expires at the end of the week, we'll create a
              brand new group. It's an opportunity to meet new people and expand
              your social circle.{"\n"}
            </Text>
            <Text style={styles.text}>
              Remember, the Friendship Hub is all about fostering connections
              and building meaningful relationships. So, jump into
              conversations, make friends, and enjoy your time here!{"\n"}
              {"\n"}
            </Text>
            <Text style={styles.text}>
              If you have any questions or need assistance, don't hesitate to
              reach out. Happy friending! 🤝🎉
            </Text>
          </ScrollView>
          <View style={styles.hr}></View>
          <View style={styles.buttonbar}>
            <TouchableOpacity
              style={[styles.button, styles.buttonpink]}
              onPress={() => setShowInfoModal(false)}
            >
              <Text style={styles.text}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
};

export default HomeScreen;
