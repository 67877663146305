import React from "react";
import { View, Image, StyleSheet } from "react-native";

const CircleImageOverlay = () => {
  return (
    <View style={styles.container}>
      <Image
        source={require("../assets/images/BFcircle400.png")}
        style={styles.mainImage}
        resizeMode="cover"
      />
      <View style={styles.overlayImages}>
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_1]}
        />
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_2]}
        />
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_3]}
        />
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_4]}
        />
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_5]}
        />
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_6]}
        />
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_7]}
        />
        <Image
          source={require("../assets/images/avatar/Avatar.png")}
          style={[styles.smallImage, styles.small_8]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 400,
    height: 400,
    position: "relative",
    alignSelf: "center",
  },
  mainImage: {
    flex: 1,
    width: null,
    height: null,
    borderRadius: 200,
    zIndex: 1,
  },
  overlayImages: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 2,
  },
  smallImage: {
    // maxWidth: 60,
    // maxHeight: 60,
    // width: 60,
    // height: 60,
    borderWidth: 4,
    borderColor: "#5784ba",
    // marginRight: 10,
    borderRadius: 50,

    width: 90,
    height: 90,
  },
  small_1: {
    // borderColor: "#9AC8EB",
    borderColor: "#B6D8F2",
    position: "absolute",
    // 172, 50
    transform: [{ translateX: 155 }, { translateY: 33 }],
  },
  small_2: {
    //257, 86
    borderColor: "#9AC8EB",
    position: "absolute",
    transform: [{ translateX: 240 }, { translateY: 69 }],
  },
  small_3: {
    //292, 171
    borderColor: "#B6D8F2",
    position: "absolute",
    transform: [{ translateX: 275 }, { translateY: 155 }],
  },
  small_4: {
    //292, 171
    borderColor: "#9AC8EB",
    position: "absolute",
    transform: [{ translateX: 240 }, { translateY: 240 }],
  },
  small_5: {
    //292, 171
    borderColor: "#B6D8F2",
    position: "absolute",
    transform: [{ translateX: 155 }, { translateY: 275 }],
  },
  small_6: {
    //292, 171
    borderColor: "#9AC8EB",
    position: "absolute",
    transform: [{ translateX: 69 }, { translateY: 240 }],
  },
  small_7: {
    //292, 171
    borderColor: "#B6D8F2",
    position: "absolute",
    transform: [{ translateX: 33 }, { translateY: 155 }],
  },
  small_8: {
    //292, 171
    borderColor: "#9AC8EB",
    position: "absolute",
    transform: [{ translateX: 69 }, { translateY: 69 }],
  },
});

export default CircleImageOverlay;
