import React, { useState, useContext, useEffect } from "react";
import { View, Text, Image, TouchableOpacity } from "react-native";
import { styles } from "../assets/Style";
import Context from "../Context/context";
import { AVATAR_URL } from "../constants"; // Import Avatar URL
import { logout } from "../bapi"; // Import the logout function from your bapi file

export default function CustomDrawer({ navigation }) {
  //const { context } = React.useContext(Context);
  const context = useContext(Context);
  const avatar = context.Avatar;
  const alias = context.Alias;

  const AvatarImage = () => {
    var AvatarURL = `${AVATAR_URL}` + avatar + ".png";

    return (
      <Image
        source={{
          uri: AvatarURL,
        }}
        style={styles.avatar}
      />
    );
  };

  const handleLogout = async () => {
    try {
      // Call the logout API function
      await logout(context.token);
      // Clear the token and navigate to the login screen
      context.setToken("");
      // Reload the Login screen
      //navigation.navigate("Login", { timestamp: Date.now() });
    } catch (error) {
      // Handle the error if the logout request fails
      alert("Error: " + error.message);
      console.error("ERROR FOUND: ", error);
    }
  };

  // Use useEffect to navigate when necessary context updates have taken effect
  useEffect(() => {
    if (context.token === "") {
      // Reload the Login screen
      navigation.navigate("Login", { timestamp: Date.now() });
    }
  }, [context.token]);

  return (
    <View style={styles.drawer}>
      <TouchableOpacity
        activeOpacity={0.5}
        style={styles.shelfX}
        onPress={() => navigation.closeDrawer()}
      >
        <Text style={styles.h2}>X</Text>
      </TouchableOpacity>
      <View style={styles.top}>
        {AvatarImage()}
        <TouchableOpacity onPress={() => navigation.navigate("Profile")}>
          <Text style={styles.text}>Edit Profile</Text>
        </TouchableOpacity>
        <View>
          <Text style={styles.h3}>Hello, {alias}</Text>
        </View>
      </View>
      <View style={styles.menu}>
        <TouchableOpacity
          activeOpacity={0.5}
          style={styles.shelf}
          onPress={() => navigation.navigate("Home", { refresh: Date.now() })}
        >
          <Text style={styles.h3}>Friendship Hub</Text>
          {context.noteGroup !== 0 && context.noteGroup && (
            <View style={styles.drawerNotification}>
              <Text style={styles.notificationText}>!</Text>
            </View>
          )}
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.5}
          style={styles.shelf}
          onPress={() => navigation.navigate("Question")}
        >
          <Text style={styles.h3}>Daily Question</Text>
          {!context.QA && (
            <View style={styles.drawerNotification}>
              <Text style={styles.notificationText}>!</Text>
            </View>
          )}
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={0.5}
          style={styles.shelf}
          onPress={() => navigation.navigate("Friends")}
        >
          <Text style={styles.h3}>My Friends</Text>
          {context.noteFriends !== 0 && context.noteFriends && (
            <View style={styles.drawerNotification}>
              <Text style={styles.notificationText}>!</Text>
            </View>
          )}
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        activeOpacity={0.5}
        style={styles.shelf}
        onPress={handleLogout}
      >
        <Text style={styles.h3}>Logout</Text>
      </TouchableOpacity>
    </View>
  );
}
