// RemainingTime.js
import React, { useState, useEffect } from "react";
import { Text, View } from "react-native";
import { styles } from "../assets/Style";

const RemainingTime = ({ remainingTime }) => {
  const [currentTime, setCurrentTime] = useState(remainingTime);

  const formatRemainingTime = (timeInSeconds) => {
    const days = Math.floor(timeInSeconds / (60 * 60 * 24));
    const hours = Math.floor((timeInSeconds / (60 * 60)) % 24);
    const minutes = Math.floor((timeInSeconds / 60) % 60);
    const seconds = Math.floor(timeInSeconds % 60);

    let formattedTime = "Time Remaining: ";

    if (days > 0) {
      formattedTime += `${days} ${days === 1 ? "day" : "days"} `;
    }

    if (hours > 0) {
      formattedTime += `${hours} ${hours === 1 ? "hour" : "hours"} `;
    }

    formattedTime += `${minutes} minutes ${seconds} seconds`;

    return formattedTime;
  };

  // Start the timer on first load.
  useEffect(() => {
    let intervalId;

    if (currentTime > 0) {
      intervalId = setInterval(() => {
        setCurrentTime((currentTime) => currentTime - 1);
      }, 1000);
    } else {
      if (currentTime !== 0) {
        setCurrentTime(0);
        clearInterval(intervalId);
      }
    }
    return () => {
      clearInterval(intervalId);
    };
  });

  useEffect(() => {
    if (remainingTime > 0) {
      setCurrentTime(remainingTime);
    } else {
      if (currentTime !== 0) {
        setCurrentTime(0);
      }
    }

    return () => {};
  }, [remainingTime]);

  return (
    <View style={styles.question}>
      <Text style={[styles.text, { alignSelf: "center" }]}>
        {currentTime ? formatRemainingTime(currentTime) : "Awaiting Matches"}
      </Text>
    </View>
  );
};

export default RemainingTime;
