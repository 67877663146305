import React, { useState, useEffect, useContext } from "react";
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  useNavigation,
  useRoute,
  useFocusEffect,
} from "@react-navigation/native";
import { styles } from "../assets/Style";
import Context from "../Context/context";
import { AVATAR_URL } from "../constants"; // Import Avatar URL
import { Ionicons } from "@expo/vector-icons"; // Import Ionicons from Expo
import { getFriendProfile, setFriend } from "../bapi";

const FriendProfileScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const groupToken = route.params.groupToken;
  const backRoute = route.params.backRoute;
  const context = useContext(Context);
  const [friendData, setFriendData] = useState({});
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      setLoading(true);

      // Call getFriendProfile when the component mounts
      getFriendProfile(context.token, groupToken)
        .then((response) => {
          setFriendData(response.Members);
        })
        .catch((error) => {
          console.error("Error fetching friend profile:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false regardless of success or failure
        });
    }, [context.token, groupToken, reload])
  );

  const handleAddFriend = () => {
    // Logic to add the friend
    setLoading(true);
    setFriend(context.token, groupToken, "Added")
      .then((response) => {
        // Handle success if needed
        setReload(!reload);
      })
      .catch((error) => {
        console.error("Error adding friend profile:", error);
      });
  };

  const handleRemoveFriend = () => {
    // Logic to remove the friend
    setLoading(true);
    setFriend(context.token, groupToken, "Remove")
      .then((response) => {
        setReload(!reload);
        // Handle success if needed
      })
      .catch((error) => {
        console.error("Error removing friend profile:", error);
      });
  };

  const handleBlockFriend = () => {
    // Logic to block or unblock the friend
    setLoading(true);
    setFriend(context.token, groupToken, "Block")
      .then((response) => {
        setReload(!reload);
        // Handle success if needed
      })
      .catch((error) => {
        console.error("Error removing friend profile:", error);
      });
  };

  const handleUnblockFriend = () => {
    // Logic to block or unblock the friend
    setLoading(true);
    setFriend(context.token, groupToken, "Unblock")
      .then((response) => {
        setReload(!reload);
        // Handle success if needed
      })
      .catch((error) => {
        console.error("Error unblocking profile:", error);
      });
  };

  const handleGoBack = () => {
    navigation.navigate(backRoute, {
      groupToken: groupToken,
      avatar: friendData.Avatar,
      alias: friendData.Alias,
      backRoute: route.name, // Pass the current route name.
    });
  };

  return (
    <View style={styles.container}>
      {loading ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="#F7F6CF" />
        </View>
      ) : (
        <View style={styles.container}>
          <View style={styles.profilePanel}>
            <View style={styles.backButton}>
              <TouchableOpacity onPress={handleGoBack}>
                <Ionicons name="arrow-back" size={24} color="black" />
                <Text style={styles.backButtonText}>Back</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.compatibilityCircle}>
              <Text style={styles.text}>{friendData.Comp}%</Text>
              <Text style={[{ fontSize: 10 }]}>Compatibility</Text>
            </View>
            <View
              style={[styles.avatarBorder, { marginBottom: 32, marginTop: 16 }]}
            >
              <Image
                source={{ uri: AVATAR_URL + friendData.Avatar + ".png" }}
                style={styles.avatar}
              />
              <View style={styles.avatarTag}>
                <Text style={styles.textwhite}>{friendData.Alias}</Text>
              </View>
            </View>
            {friendData.user_status === "none" ||
            friendData.user_status === "pending" ? (
              <TouchableOpacity
                style={[styles.button, styles.buttonblue]}
                onPress={handleAddFriend}
              >
                <Text style={styles.textwhite}>Add Friend</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={[styles.button, styles.buttonpink]}
                onPress={handleRemoveFriend}
              >
                <Text style={styles.text}>Remove Friend</Text>
              </TouchableOpacity>
            )}
            {friendData.user_banned === 0 ? (
              <TouchableOpacity
                style={[styles.button, styles.button]}
                onPress={handleBlockFriend}
              >
                <Text style={styles.text}>Block Friend</Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={[styles.button, styles.buttonpink]}
                onPress={handleUnblockFriend}
              >
                <Text style={styles.text}>Unblock Friend</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}
    </View>
  );
};

export default FriendProfileScreen;
