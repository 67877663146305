import React, { useState } from "react";
import {
  View,
  Text,
  Platform,
  KeyboardAvoidingView,
  Image,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Alert,
} from "react-native";
import { styles } from "../assets/Style";
import Feather from "react-native-vector-icons/Feather";
import { resetPassword, verifyPin } from "../bapi";
import { useNavigation, useRoute } from "@react-navigation/native";

const PasswordResetScreen = ({}) => {
  const navigation = useNavigation();
  const route = useRoute();
  const backRoute = route.params.backRoute;
  const [email, setEmail] = useState();
  const [pin, setPIN] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [confirmSecureTextEntry, setConfirmSecureTextEntry] = useState(true);
  const [error, setError] = useState("");
  const [step, setStep] = useState(0); // 0: Enter email, 1: Enter PIN and new password

  const handleSendPINEmail = async () => {
    if (step === 0) {
      if (email.length === 0) {
        setError("Please enter display name and email.");
        return;
      }

      if (!isValidEmail(email)) {
        setError("Invalid email.");
        return;
      }

      //InsertRecord();
      // Implementation of sending PIN email goes here
      const data = await resetPassword(email);
      if (data.error) {
        setError(data.message);
      } else {
        setError(""); // Clear any previous errors
        setStep(1);
      }

      // Assuming the email was sent successfully, proceed to the next step
    } else if (step === 1) {
      if (pin.length === 0 || newPassword.length === 0) {
        setError("Please enter PIN and password.");
        return;
      }

      // Implement user sign-up process here
      // UpdateRecord();
    }
  };

  const isValidEmail = (value) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const handleBack = () => {
    // Assuming the password was updated successfully, reset the state and show success message
    setPIN("");
    setNewPassword("");
    setStep(0);
    setEmail("");
    navigation.navigate(backRoute);
  };
  const handleUpdatePassword = async () => {
    // Implementation of updating password goes here
    //Alert.alert("Success", "Password updated successfully.");
  };

  const UpdateRecord = async () => {
    try {
      const username = "";
      const data = await verifyPin(username, email, pin, newPassword);
      if (data.error) {
        setError(data.message);
      } else {
        setError(""); // Clear any previous errors
        handleBack();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const updateSecureTextEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  const updateConfirmSecureTextEntry = () => {
    setConfirmSecureTextEntry(!confirmSecureTextEntry);
  };

  const renderStepOne = () => (
    <View style={styles.panel}>
      <Image
        style={styles.logoImage}
        source={require("../assets/images/BFcircle.png")}
      />
      {error ? <Text style={styles.errorText}>{error}</Text> : null}
      <Text style={[styles.directionText, styles.question]}>
        Enter your email:
      </Text>
      <Text style={styles.textlabel}>Email</Text>
      <View style={styles.row}>
        <TextInput
          style={styles.textinput}
          placeholder="Enter Email Address"
          value={email}
          onChangeText={setEmail}
        />
      </View>
      <View style={styles.buttonbar}>
        <TouchableOpacity
          style={[styles.button, styles.buttonyellow]}
          onPress={() => handleBack()}
        >
          <Text style={styles.text}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.buttonpink]}
          onPress={handleSendPINEmail}
        >
          <Text style={styles.text}>Send PIN Email</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  const renderStepTwo = () => (
    <View style={styles.panel}>
      <Image
        style={styles.logoImage}
        source={require("../assets/images/BFcircle.png")}
      />
      {error ? <Text style={styles.errorText}>{error}</Text> : null}
      <Text style={[styles.directionText, styles.question]}>
        Enter the PIN and password for your account:
      </Text>
      <Text style={styles.textlabel}>PIN</Text>
      <View style={styles.row}>
        <TextInput
          style={styles.textinput}
          placeholder="Enter PIN"
          value={pin}
          onChangeText={setPIN}
        />
      </View>
      <Text style={styles.textlabel}>New Password</Text>
      <View style={styles.row}>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.textinput}
            placeholder="New Password"
            secureTextEntry={secureTextEntry}
            value={newPassword}
            onChangeText={setNewPassword}
          />
          <TouchableOpacity
            style={styles.eyeIcon}
            onPress={updateSecureTextEntry}
          >
            {secureTextEntry ? (
              <Feather name="eye-off" color="grey" size={20} />
            ) : (
              <Feather name="eye" color="black" size={20} />
            )}
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.buttonbar}>
        <TouchableOpacity
          style={[styles.button, styles.buttonyellow]}
          onPress={() => setStep(0)}
        >
          <Text style={styles.text}>Back</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, styles.buttonpink]}
          onPress={UpdateRecord}
        >
          <Text style={styles.text}>Update Password</Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  let renderStep = null;
  if (step === 0) {
    renderStep = renderStepOne;
  } else if (step === 1) {
    renderStep = renderStepTwo;
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={100}
      enabled
      style={styles.Bodyscroll}
    >
      <ScrollView>{renderStep && renderStep()}</ScrollView>
    </KeyboardAvoidingView>
  );
};

export default PasswordResetScreen;
