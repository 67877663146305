import React from "react";
import Context from "./context";

export default class GlobalState extends React.Component {
  state = {
    Alias: "Unknown User",
    token: "",
    Email: "Stranger@Befriender.ca",
    Avatar: "B_0_0_0_0_0",
    AKID: 0,
    GID: 0,
    //
    tabIndex: 0,
    // default values set from Avatar string on login.
    colorBody: 0,
    indexEyes: 0,
    indexMouth: 0,
    indexHats: 0,
    indexRoom: 0,
    // Questions
    QID: 1,
    QA: 0,
    QATime: "",
    question: "",
    anum: 0,
    a0: "",
    a1: "",
    a2: "",
    a3: "",
    a4: "",
    a5: "",
    a6: "",
    a7: "",
    a8: "",
    a9: "",
    noteHeader: 0,
    noteGroup: 0,
    noteFriends: 0,
  };

  setToken = (token) => {
    this.setState({ token: token });
  };

  setAlias = (Alias) => {
    this.setState({ Alias: Alias });
  };
  setEmail = (Email) => {
    this.setState({ Email: Email });
  };

  setAvatar = (Avatar) => {
    // split the Avatar in to an array.
    var AvatarData = Avatar.split("_");
    // Set Avatar default state to Avatar.
    // B_$room_$body_$eyes_$mouth_$hat
    this.setState({
      Avatar: Avatar,
      indexRoom: AvatarData[1],
      colorBody: AvatarData[2],
      indexEyes: AvatarData[3],
      indexMouth: AvatarData[4],
      indexHats: AvatarData[5],
    });
  };

  setBody = (colorBody) => {
    this.setState({ colorBody: colorBody });
  };

  setEyes = (indexEyes) => {
    this.setState({ indexEyes: indexEyes });
  };

  setMouth = (indexMouth) => {
    this.setState({ indexMouth: indexMouth });
  };

  setHat = (indexHats) => {
    this.setState({ indexHats: indexHats });
  };

  setRoom = (indexRoom) => {
    this.setState({ indexRoom: indexRoom });
  };

  setTab = (tabIndex) => {
    this.setState({ tabIndex: tabIndex });
  };

  setQID = (QID) => {
    this.setState({ QID: QID });
  };
  setQA = (QA) => {
    this.setState({ QA: QA });
  };
  setQATime = (QATime) => {
    this.setState({ QATime: QATime });
  };
  setAKID = (AKID) => {
    this.setState({ AKID: AKID });
  };
  setGID = (GID) => {
    this.setState({ GID: GID });
  };
  setNoteHeader = (noteHeader) => {
    this.setState({ noteHeader: noteHeader });
  };

  setNoteGroup = (noteGroup) => {
    this.setState({ noteGroup: noteGroup });
  };
  setNoteFriends = (noteFriends) => {
    this.setState({ noteFriends: noteFriends });
  };

  setquestion = (question) => {
    this.setState({
      question: question.question,
      anum: question.anum,
      a0: question.a0,
      a1: question.a1,
      a2: question.a2,
      a3: question.a3,
      a4: question.a4,
      a5: question.a5,
      a6: question.a6,
      a7: question.a7,
      a8: question.a8,
      a9: question.a9,
    });
  };

  render() {
    return (
      <Context.Provider
        value={{
          token: this.state.token,
          setToken: this.setToken,
          Alias: this.state.Alias,
          setAlias: this.setAlias,
          Email: this.state.Email,
          setEmail: this.setEmail,
          Avatar: this.state.Avatar,
          setAvatar: this.setAvatar,
          colorBody: this.state.colorBody,
          setBody: this.setBody,
          indexEyes: this.state.indexEyes,
          setEyes: this.setEyes,
          indexMouth: this.state.indexMouth,
          setMouth: this.setMouth,
          indexHats: this.state.indexHats,
          setHat: this.setHat,
          indexRoom: this.state.indexRoom,
          setRoom: this.setRoom,
          tabIndex: this.state.tabIndex,
          setTab: this.setTab,
          QID: this.state.QID,
          setQID: this.setQID,
          QA: this.state.QA,
          setQA: this.setQA,
          QATime: this.state.QATime,
          setQATime: this.setQATime,
          AKID: this.state.AKID,
          setAKID: this.setAKID,
          GID: this.state.GID,
          setGID: this.setGID,
          setquestion: this.setquestion,
          question: this.state.question,
          anum: this.state.anum,
          a0: this.state.a0,
          a1: this.state.a1,
          a2: this.state.a2,
          a3: this.state.a3,
          a4: this.state.a4,
          a5: this.state.a5,
          a6: this.state.a6,
          a7: this.state.a7,
          a8: this.state.a8,
          a9: this.state.a9,
          setNoteHeader: this.setNoteHeader,
          noteHeader: this.state.noteHeader,
          setNoteGroup: this.setNoteGroup,
          noteGroup: this.state.noteGroup,
          setNoteFriends: this.setNoteFriends,
          noteFriends: this.state.noteFriends,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
