import React from "react";

export default React.createContext({
  Alias: "Unknown User",
  setAlias: (Alias) => {},
  token: "",
  setToken: (token) => {},
  Email: "Stranger@Befriender.ca",
  setEmail: (Email) => {},
  Avatar: "B_0_0_0_0_0",
  setAvatar: (Avatar) => {},
  QID: 1,
  setQID: (QID) => {},
  QA: 0,
  setQA: (QA) => {},
  qaTime: "",
  akid: 0,
  gid: 0,
  tabIndex: 0,
  setTab: (tabIndex) => {},
  colorBody: 0,
  indexEyes: 0,
  indexMouth: 0,
  indexHats: 0,
  indexRoom: 0,
  question: "",
  anum: 0,
  a0: "",
  a1: "",
  a2: "",
  a3: "",
  a4: "",
  a5: "",
  a6: "",
  a7: "",
  a8: "",
  a9: "",
  noteHeader: 0,
  setNoteHeader: (noteHeader) => {},
  noteGroup: 0,
  setNoteGroup: (noteGroup) => {},
  noteQuestion: 0, //QA
  noteFriends: 0,
  setNoteFriends: (noteFriends) => {},
});
