import React from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { styles } from "../assets/Style";
import { AVATAR_URL } from "../constants";

const GroupScreen = ({ navigation, groupAvatars, groupTitle }) => {
  const route = useRoute();

  const handleAvatarPress = (groupToken, alias, avatar, status) => {
    if (status == "none" || status == "accepted") {
      navigation.navigate("Chat", {
        groupToken,
        alias,
        avatar,
        backRoute: route.name,
      });
    } else {
      navigation.navigate("FriendProfile", {
        groupToken,
        alias,
        avatar,
        backRoute: route.name, // Pass the current route name.
      });
    }
  };

  // Define a function to return colors based on user status
  function getStatusColor(userBanned, userStatus) {
    if (userBanned) {
      return "black"; // Default color for unknown status
    }
    switch (userStatus) {
      case "none": // The user has no status, probably in a group
        return "#333666";
      case "accepted":
        return "#333666"; // Green color for online status
      case "added":
        return "gray"; // Gray color for offline status
      case "pending":
        return "gray"; // Gray color for offline status

      // Add more cases for different user statuses
      default:
        return "black"; // Default color for unknown status
    }
  }

  return (
    <View style={styles.container}>
      <View style={styles.panel}>
        {groupTitle && (
          <View style={styles.Header}>
            <Text style={styles.HeaderFont}>{groupTitle}</Text>
          </View>
        )}
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            maxWidth: 450,
            justifyContent: "space-around",
          }}
        >
          {groupAvatars.map((avatar, index) => (
            <TouchableOpacity
              key={index}
              style={{ alignItems: "center" }}
              onPress={() =>
                handleAvatarPress(
                  avatar.token,
                  avatar.alias,
                  avatar.avatar,
                  avatar.user_status
                )
              }
            >
              <View>
                <Image
                  source={{ uri: AVATAR_URL + avatar.avatar + ".png" }}
                  style={[
                    styles.avatarRound,
                    {
                      borderColor: getStatusColor(
                        avatar.user_banned,
                        avatar.user_status
                      ),
                    },
                  ]}
                />
                {avatar.note !== 0 && avatar.note && (
                  <View style={styles.groupNotification}>
                    <Text style={styles.notificationText}>!</Text>
                  </View>
                )}
              </View>
              <View
                style={[
                  styles.avatarTag,
                  {
                    backgroundColor: getStatusColor(
                      avatar.user_banned,
                      avatar.user_status
                    ),
                  },
                ]}
              >
                <Text style={styles.textwhite}>{avatar.alias}</Text>
              </View>
            </TouchableOpacity>
          ))}
          {groupAvatars.length < 2 && <View style={{ flex: 1 }} />}
        </View>
      </View>
    </View>
  );
};

export default GroupScreen;
