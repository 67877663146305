import React, { useEffect, useContext } from "react";
import { View, Text, Image, ScrollView, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { styles } from "../assets/Style";
import SkinColor from "../assets/images/avatar/body/body.json";
import eyesIndex from "../assets/images/avatar/eyes/eyes.json";
import mouthIndex from "../assets/images/avatar/mouth/mouth.json";
import hatsIndex from "../assets/images/avatar/hats/hats.json";
import roomIndex from "../assets/images/avatar/room/room.json";
import AvatarTabs from "../assets/images/avatar/tabs.json";
import Context from "../Context/context";
import { generateAvatar } from "../bapi";
import { AVATAR_URL } from "../constants";

// const eyes = eyesIndex.map((item) => ({
//   image: require(`../assets/images/avatar/eyes/${item.image}.png`),
//   ID: item.id,
// }));

{
  /* <Image
                source={{ uri: `${AVATAR_URL}${groupAvatar}.png` }}
                style={styles.MsgImg}
              /> */
}

const eyes = eyesIndex.map((item) => {
  return {
    image: { uri: `${AVATAR_URL}avatar/eyes/${item.image}.png` },
    ID: item.id,
  };
});

const mouth = mouthIndex.map((item) => ({
  image: { uri: `${AVATAR_URL}avatar/mouth/${item.image}.png` },
  //image: require(`../assets/images/avatar/mouth/${item.image}.png`),
  ID: item.id,
}));

const hats = hatsIndex.map((item) => ({
  //  image: require(`../assets/images/avatar/hats/${item.image}.png`),
  image: { uri: `${AVATAR_URL}avatar/hats/${item.image}.png` },
  ID: item.id,
}));

const room = roomIndex.map((item) => ({
  //image: require(`../assets/images/avatar/room/${item.image}.png`),
  image: { uri: `${AVATAR_URL}avatar/room/${item.image}.png` },
  ID: item.id,
}));

const AvatarScreen = () => {
  const navigation = useNavigation();
  const {
    tabIndex,
    setTab,
    token,
    colorBody,
    indexEyes,
    indexMouth,
    indexHats,
    indexRoom,
    setBody,
    setEyes,
    setMouth,
    setHat,
    setRoom,
    setAvatar,
  } = useContext(Context);

  useEffect(() => {
    // Fetch data or perform any initial setup
  }, []);

  const tabslist = () => {
    return AvatarTabs.map((element, index) => {
      return (
        <TouchableOpacity
          key={element.key}
          style={styles.tabs}
          onPress={() => setTab(index)}
        >
          <Text style={styles.text}>{element.title}</Text>
        </TouchableOpacity>
      );
    });
  };

  const GoTab = (tabIndex) => {
    if (tabIndex === 0) {
      return BodyCardCreator(tabIndex, SkinColor);
    }
    if (tabIndex === 1) {
      return ImageCardCreator(tabIndex, eyes);
    }
    if (tabIndex === 2) {
      return ImageCardCreator(tabIndex, mouth);
    }
    if (tabIndex === 3) {
      return ImageCardCreator(tabIndex, hats);
    }
    if (tabIndex === 4) {
      return ImageCardCreator(tabIndex, room);
    }
  };

  const SetAvatar = (layer, item) => {
    if (layer === 0) {
      return setBody(item);
    }
    if (layer === 1) {
      return setEyes(item);
    }
    if (layer === 2) {
      return setMouth(item);
    }
    if (layer === 3) {
      return setHat(item);
    }
    if (layer === 4) {
      return setRoom(item);
    }
  };

  const ImageCardCreator = (layer, itemList) => {
    const itemCards = itemList.map((c, index) => {
      return (
        <TouchableOpacity
          key={index}
          onPress={() => SetAvatar(layer, c.ID)}
          style={{ margin: 2 }}
        >
          <Image source={c.image} style={styles.avatarIcon} />
        </TouchableOpacity>
      );
    });

    return (
      <ScrollView
        contentContainerStyle={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {itemCards}
      </ScrollView>
    );
  };

  const BodyCardCreator = (layer, itemList) => {
    const itemCards = itemList.map((c, index) => {
      return (
        <TouchableOpacity
          key={index}
          onPress={() => SetAvatar(layer, c.id)}
          style={{ margin: 2 }}
        >
          <Image
            source={require("../assets/images/avatar/Avatar.png")}
            style={[styles.avatarIcon, { tintColor: c.color }]}
          />
        </TouchableOpacity>
      );
    });

    return (
      <ScrollView
        contentContainerStyle={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
        }}
      >
        {itemCards}
      </ScrollView>
    );
  };

  const GenerateAvatar = () => {
    const data = {
      token,
      bodyIndex: colorBody,
      indexEyes,
      indexMouth,
      indexHats,
      indexRoom,
    };

    generateAvatar(
      data.token,
      data.bodyIndex,
      data.indexEyes,
      data.indexMouth,
      data.indexHats,
      data.indexRoom
    )
      .then((response) => {
        if (response.error) {
          alert("Error: " + response.message);
        } else {
          setAvatar(response.avatar);
          navigation.navigate("Profile"); // Navigate to the profile page
        }
      })
      .catch((error) => {
        alert("Error Occurred: " + error.message);
        console.error("ERROR FOUND", error);
      });
  };

  return (
    <View style={styles.Bodyscroll}>
      <View style={styles.AvatarPanel}>
        <View style={styles.avatarBorder}>
          <Image
            source={room.find((item) => item.ID == indexRoom)?.image}
            style={styles.avatarLayer}
          />
          <Image
            source={require("../assets/images/avatar/body/body.png")}
            style={[
              styles.avatarLayer,
              {
                tintColor: SkinColor.find((item) => item.id == colorBody)
                  ?.color,
              },
            ]}
          />
          <Image
            source={require("../assets/images/avatar/body/bodyoutline.png")}
            style={styles.avatarLayer}
          />
          <Image
            source={eyes.find((item) => item.ID == indexEyes)?.image}
            style={styles.avatarLayer}
          />
          <Image
            source={mouth.find((item) => item.ID == indexMouth)?.image}
            style={styles.avatarLayer}
          />
          <Image
            source={hats.find((item) => item.ID == indexHats)?.image}
            style={styles.avatarLayer}
          />
        </View>
        <TouchableOpacity
          style={[styles.button, styles.buttonpink]}
          onPress={GenerateAvatar}
        >
          <Text style={styles.text}>Looks Good</Text>
        </TouchableOpacity>
        <View style={styles.tabsPanel}>
          <View style={{ flexDirection: "row" }}>{tabslist()}</View>
          <View style={styles.tabsContent}>{GoTab(tabIndex)}</View>
        </View>
      </View>
    </View>
  );
};

export default AvatarScreen;
