import { StyleSheet, StatusBar, Dimensions } from "react-native";
import colors from "./colors";

const styles = StyleSheet.create({
  LogoBar: {
    marginTop: StatusBar.currentHeight,
    backgroundColor: colors.bfc1,
    padding: 10,
    flexDirection: "row",
  },
  LogoFont: {
    fontFamily: "Sofia",
    fontSize: 36,
    color: "#FFF",
    marginLeft: 20,
  },
  logoImage: {
    alignSelf: "center",
    marginBottom: 10,
    width: 200,
    height: 200,
  },
  errorText: {
    fontFamily: "JosefinSans_400Regular",
    color: "#FFF",
    fontSize: 18,
    margin: 10,
    padding: 10,
    borderRadius: 10,
    textAlign: "center",
    backgroundColor: "red",
  },
  Body: {
    flex: 1,
    alignItems: "center",
    backgroundColor: colors.bfc2,
  },
  container: {
    flex: 1,
    backgroundColor: colors.bfc2,
  },
  inputContainer: {
    position: "relative",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  groupContainer: {
    flex: 1,
    width: "100%", // Adjust the width as needed
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  eyeIcon: {
    position: "absolute",
    right: 10,
    zIndex: 1,
  },
  Bodyscroll: {
    flex: 1,
    backgroundColor: colors.bfc2,
  },
  HeaderFont: {
    fontFamily: "HappyMonkey_400Regular",
    color: colors.bfcw,
    fontSize: 34,
  },
  Header: {
    backgroundColor: colors.bfc1,
    borderRadius: 10,
    marginBottom: 10,
    padding: 20,
    alignItems: "center",
  },
  HeaderInfo: {
    position: "absolute",
    right: 16,
    backgroundColor: colors.bfcw, // Background color for the indicator
    borderRadius: 16, // Adjust the border radius as needed
    width: 32, // Adjust the width as needed
    height: 32, // Adjust the height as needed
  },
  HeaderInfoText: {
    color: colors.bfc1,
    fontSize: 30,
    fontWeight: "bold",
    fontFamily: "JosefinSans_400Regular",
    textAlign: "center",
    lineHeight: 34,
  },
  notificationIndicator: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: colors.bfc5, // Background color for the indicator
    borderRadius: 10, // Adjust the border radius as needed
    width: 20, // Adjust the width as needed
    height: 20, // Adjust the height as needed
    justifyContent: "center",
    alignItems: "center",
  },

  notificationText: {
    color: "black", // Text color for the "!"
    fontWeight: "bold",
    fontSize: 16, // Adjust the font size as needed
  },

  drawerNotification: {
    position: "absolute",
    top: 10,
    right: 10,
    backgroundColor: colors.bfc5, // Background color for the indicator
    borderRadius: 10, // Adjust the border radius as needed
    width: 20, // Adjust the width as needed
    height: 20, // Adjust the height as needed
    justifyContent: "center",
    alignItems: "center",
  },

  groupNotification: {
    position: "absolute",
    top: -5,
    right: -5,
    backgroundColor: colors.bfc5, // Background color for the indicator
    borderRadius: 10, // Adjust the border radius as needed
    width: 20, // Adjust the width as needed
    height: 20, // Adjust the height as needed
    justifyContent: "center",
    alignItems: "center",
  },

  panel: {
    flex: 1,
    padding: 5,
    marginTop: 3,
    width: "100%",
    maxWidth: 500,
    alignSelf: "center",
  },

  modalPanel: {
    //flex: 1,
    padding: 5,
    marginTop: 3,
    //maxWidth: 500,
    alignSelf: "center",
    backgroundColor: colors.bfcw,
    borderRadius: 10,
    // marginTop: 10,
    // marginBottom: 10,
    margin: 10,
    // width: "95%",
    maxWidth: 500,
  },

  modalContent: {
    borderRadius: 10,
    backgroundColor: colors.bfc2,
    paddingHorizontal: 16,
    paddingVertical: 24,
    maxHeight: 400, // Adjust the maximum height as needed
  },
  profilePanel: {
    padding: 4,
    marginTop: 20,
    width: "95%",
    maxWidth: 500,
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 10,
    backgroundColor: colors.bfcw,
  },

  questionPanel: {
    padding: 5,
    backgroundColor: colors.bfcw,
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    width: "95%",
    maxWidth: 500,
  },
  question: {
    padding: 10,
    backgroundColor: colors.bfc4,
    borderRadius: 10,
  },
  hr: {
    height: 1,
    backgroundColor: colors.bfc1,
    marginBottom: 10,
    marginTop: 10,
  },
  answers: {
    backgroundColor: colors.bfc3,
    alignItems: "center",
    borderRadius: 10,
    padding: 10,
    margin: 10,
  },
  drawer: {
    backgroundColor: colors.bfc4,
    flex: 1,
    paddingTop: StatusBar.currentHeight,
  },
  top: {
    backgroundColor: colors.bfc4,
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    backgroundColor: colors.bfc2,
    flex: 1,
    padding: 4,
    paddingTop: 8,
    paddingBottom: 8,
    borderTopStartRadius: 10,
    borderTopEndRadius: 10,
    alignItems: "center",
    marginHorizontal: 2,
  },
  tabsPanel: {
    padding: 3,
    backgroundColor: "#FFF",
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 500,
    flex: 1,
  },
  tabsContent: {
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: colors.bfcw,
    flex: 1,
  },
  AvatarPanel: {
    flex: 1,
    padding: 4,
    marginTop: 20,
    width: "100%",
    maxWidth: 500,
    alignSelf: "center",
    alignItems: "center",
  },
  avatar: {
    borderRadius: 10,
    height: 200,
    width: 200,
    marginBottom: 5,
    backgroundColor: colors.bfcw,
  },
  avatarOutline: {
    borderRadius: 10,
    borderWidth: 5,
    height: 200,
    width: 200,
    borderColor: "#333666",
  },
  avatarBorder: {
    alignItems: "center",
    height: 200,
  },
  avatarLayer: {
    borderRadius: 10,
    borderWidth: 5,
    width: 200,
    height: 200,
    position: "absolute",
    borderColor: "#333666",
  },
  avatarIcon: {
    borderRadius: 10,
    width: 77,
    height: 77,
    backgroundColor: "#FFF",
  },
  avatarRound: {
    borderRadius: 10,
    borderWidth: 5,
    borderColor: "#333666",
    height: 96,
    width: 96,
  },
  avatarTag: {
    backgroundColor: "#333666",
    borderRadius: 10,
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    bottom: 10,
  },
  loadicon: {
    height: 96,
    width: 96,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.bfc2,
  },
  text: {
    fontFamily: "JosefinSans_400Regular",
    fontSize: 18,
  },
  textwhite: {
    fontFamily: "JosefinSans_400Regular",
    color: "#FFF",
    fontSize: 18,
  },
  textlabel: {
    fontFamily: "JosefinSans_400Regular",
    fontSize: 18,
    fontWeight: "bold",
  },
  directionText: {
    fontSize: 18,
    fontFamily: "JosefinSans_400Regular",
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  textinput: {
    borderRadius: 5,
    padding: 8,
    marginTop: 3,
    marginBottom: 10,
    backgroundColor: colors.bfcw,
    flex: 1,
  },
  backButton: {
    position: "absolute",
    top: 16,
    left: 16,
    zIndex: 1,
  },
  backButtonText: {
    fontSize: 16,
    marginLeft: 8,
    fontFamily: "JosefinSans_400Regular",
  },
  compatibilityCircle: {
    position: "absolute",
    top: 64,
    right: 32,
    zIndex: 1,
    borderWidth: 5,
    height: 80,
    width: 80,
    borderRadius: 40,
    borderColor: "#333666",
    backgroundColor: colors.bfcw,
    justifyContent: "center",
    alignItems: "center",
  },
  ChatView: {
    flex: 1,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 8,
    backgroundColor: colors.bfc3,
  },
  chatBottom: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    backgroundColor: colors.bfcw,
    flexDirection: "row",
    padding: 8,
  },
  MsgContainer: {
    borderWidth: 2,
    borderColor: colors.bfc1,
    backgroundColor: colors.bfcw,
    flexDirection: "row",
    borderRadius: 40,
    padding: 10,
    paddingBottom: 20,
    paddingRight: 20,
    marginBottom: 10,
  },
  MsgImg: {
    maxWidth: 60,
    maxHeight: 60,
    width: 60,
    height: 60,
    borderWidth: 4,
    borderColor: "#5784ba",
    marginRight: 10,
    borderRadius: 50,
  },
  MsgTag: {
    fontWeight: "bold",
    textAlign: "left",
    fontSize: 10,
    marginBottom: 5,
  },
  headerTab: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
  },
  headerIcon: {
    marginRight: 12,
  },
  MsgTime: {
    fontSize: 10,
    flex: 1,
    textAlign: "right",
  },
  h1: {
    fontSize: 32,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 10,
    fontFamily: "JosefinSans_400Regular",
  },
  h2: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 13,
    marginBottom: 13,
    fontFamily: "JosefinSans_400Regular",
  },
  h3: {
    fontSize: 19,
    fontWeight: "bold",
    paddingTop: 16,
    paddingBottom: 16,
    fontFamily: "JosefinSans_400Regular",
  },
  h4: {
    fontSize: 19,
    fontWeight: "bold",
    paddingTop: 21,
    paddingBottom: 21,
    fontFamily: "JosefinSans_400Regular",
  },
  menu: {
    flex: 1,
    backgroundColor: colors.bfcw,
  },
  shelf: {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: colors.bfcw,
  },
  shelfX: {
    alignItems: "flex-end",
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  button: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: colors.bfc4,
    borderRadius: 15,
    margin: 10,
    borderBottomColor: "#999",
    borderBottomWidth: 3,
    alignItems: "center",
  },
  buttonpink: {
    backgroundColor: colors.bfc5,
  },
  buttonblue: {
    backgroundColor: colors.bfc1,
  },
  buttonbar: {
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center",
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  gamesButtonContainer: {
    marginLeft: "auto",
    paddingHorizontal: 20,
    paddingVertical: 6,
    borderRadius: 15,
    backgroundColor: colors.bfc1,
  },
  gamesButtonText: {
    color: "#FFF",
    fontSize: 16,
    fontWeight: "bold",
  },
  gameContainer: {
    borderWidth: 2,
    borderColor: colors.bfc1,
    backgroundColor: colors.bfc5,
    // flexDirection: "row",
    borderRadius: 10,
    padding: 10,
    // paddingBottom: 20,
    // paddingRight: 20,
    marginBottom: 10,
  },
  gameInput: {
    borderRadius: 5,
    padding: 8,
    marginTop: 3,
    marginBottom: 10,
    backgroundColor: colors.bfcw,
  },
});

export { styles };
