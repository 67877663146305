import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { styles } from "../assets/Style"; // Import the styles from your Style.js file
import { getFriendAvatars, inviteFriend } from "../bapi";
import GroupScreen from "../components/GroupScreen";
import Context from "../Context/context";
import { useFocusEffect } from "@react-navigation/native";

const FriendsScreen = ({ navigation }) => {
  const [email, setEmail] = useState("");
  const [groupAvatars, setGroupAvatars] = useState([]);
  const context = useContext(Context);
  const [loading, setLoading] = useState(false); // Add a loading state

  // useEffect(() => {
  //   fetchAvatars();
  // }, [context.token]); // Add context.token as a dependency

  useFocusEffect(
    React.useCallback(() => {
      setLoading(true);
      fetchAvatars();
      setLoading(false);
    }, [context.token])
  );

  const handleInvite = async () => {
    if (email) {
      const response = await inviteFriend(context.token, email);
      setEmail("");
      fetchAvatars();
    }
  };

  const fetchAvatars = async () => {
    try {
      context.setNoteFriends(0);
      if (context.token === "") return; // Add condition to check groupExpiryDate
      const response = await getFriendAvatars(context.token);
      if (response.error) {
        throw new Error(response.message);
      }
      const groupMembers = response.groupMembers;
      // Check if any member has Note set to true
      const hasNote = groupMembers.some((member) => member.Note === 1);

      const avatars = groupMembers.map((member) => ({
        avatar: member.Avatar,
        alias: member.Alias,
        token: member.GroupToken,
        user_status: member.user_status,
        user_banned: member.user_banned,
        note: member.Note,
      }));
      if (hasNote) {
        if (context.noteFriends !== 1) {
          context.setNoteFriends(1);
        }
      }
      setGroupAvatars(avatars);
    } catch (error) {
      console.error("Error fetching avatars:", error);
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#F7F6CF" />
      </View>
    );
  }

  return (
    <ScrollView style={styles.Bodyscroll}>
      <View style={styles.panel}>
        <View style={styles.question}>
          <Text style={[styles.text, { alignSelf: "center" }]}>
            Invite Friends by Email
          </Text>
          <View style={styles.row}>
            <TextInput
              style={styles.textinput}
              placeholder="Enter email"
              value={email}
              onChangeText={setEmail}
            />
            <TouchableOpacity
              style={[styles.button, styles.buttonblue]}
              onPress={handleInvite}
            >
              <Text style={styles.textwhite}>Invite</Text>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.hr}></View>
        <View style={styles.Header}>
          <Text style={styles.HeaderFont}>My Friends</Text>
        </View>
        <View style={styles.groupContainer}>
          <GroupScreen navigation={navigation} groupAvatars={groupAvatars} />
        </View>
      </View>
    </ScrollView>
  );
};

export default FriendsScreen;
