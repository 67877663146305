import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { styles } from "../assets/Style";
import { AVATAR_URL } from "../constants";
import Context from "../Context/context";
import ChatComponent from "../components/ChatComponent";
import GamesModal from "../components/GamesModal";
import { Ionicons } from "@expo/vector-icons"; // Import Ionicons from Expo

const ChatScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const groupToken = route.params.groupToken;
  const groupAlias = route.params.alias;
  const groupAvatar = route.params.avatar;
  const backRoute = route.params.backRoute;
  const context = useContext(Context);
  //const { token, Alias, Avatar } = context;
  const lastMessageIdRef = useRef(0);
  const [mainBackRoute, setBackRoute] = useState(backRoute);
  const [isModalVisible, setModalVisible] = useState(false);

  const goToFriendProfile = () => {
    // Only navigate to FriendProfile if groupAvatar is populated
    if (groupAvatar) {
      navigation.navigate("FriendProfile", {
        groupToken: groupToken,
        avatar: groupAvatar,
        alias: groupAlias,
        backRoute: route.name, // Pass the current route name.
      });
    }
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  useEffect(() => {
    if (backRoute == "Games" || backRoute == "FriendProfile") {
    } else {
      setBackRoute(backRoute);
    }

    return () => {};
  }, [backRoute]);

  // This needs to go back to group screen (home or friends only)
  // Do the opposite, have it understand we came back from Games or Friend Profile.
  const handleGoBack = () => {
    if (mainBackRoute == "Games" || mainBackRoute == "FriendProfile") {
    } else {
      navigation.navigate(mainBackRoute, { refresh: Date.now() });
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={84}
      enabled
      style={styles.Bodyscroll}
    >
      <View style={styles.panel}>
        <View style={styles.headerTab}>
          <View style={[{ marginRight: 8 }]}>
            <TouchableOpacity onPress={handleGoBack}>
              <Ionicons name="arrow-back" size={24} color="black" />
              {/* <Text style={styles.backButtonText}>Back</Text> */}
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            style={styles.headerIcon}
            onPress={goToFriendProfile}
          >
            {groupAvatar ? (
              <Image
                source={{ uri: `${AVATAR_URL}${groupAvatar}.png` }}
                style={styles.MsgImg}
              />
            ) : (
              <Image
                source={require("../assets/images/BFcircle4.png")}
                style={styles.MsgImg}
              />
            )}
          </TouchableOpacity>
          <View style={styles.headerTitle}>
            {groupAlias && <Text style={styles.h3}>{groupAlias}</Text>}
          </View>
          <TouchableOpacity
            style={styles.gamesButtonContainer}
            onPress={toggleModal}
          >
            <Text style={styles.gamesButtonText}>Games</Text>
          </TouchableOpacity>
        </View>
        <ChatComponent
          groupToken={groupToken}
          lastMessageIdRef={lastMessageIdRef}
        />
      </View>
      <GamesModal
        isVisible={isModalVisible}
        toggleModal={toggleModal}
      ></GamesModal>
    </KeyboardAvoidingView>
  );
};

export default ChatScreen;
