import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  Alert,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  Image,
  ActivityIndicator,
} from "react-native";
import { styles } from "../assets/Style";
import Feather from "react-native-vector-icons/Feather";
import { StatusBar } from "expo-status-bar";
import { login } from "../bapi";
import Context from "../Context/context";

const LoginScreen = ({ navigation }) => {
  const context = useContext(Context);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [secureTextEntry, setSecureTextEntry] = useState(true);
  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false); // Add a loading state

  const handleLogin = async () => {
    setLoading(true); // Set loading to true when login is initiated
    try {
      const data = await login(email, password);
      if (data.error) {
        // Handle the error and display a message to the user
        setError(data.message);
      } else {
        // Process the response data
        context.setAlias(data.Alias);
        context.setAvatar(data.Avatar);
        context.setQID(data.QID);
        context.setQA(data.QA);
        context.setToken(data.Token);
      }
    } catch (error) {
      // Handle the fetch error and display a message to the user
      setError("An error occurred.");
      Alert.alert("Error", error.message);
      console.error("ERROR FOUND: ", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleSecureTextEntry = () => {
    setSecureTextEntry(!secureTextEntry);
  };

  // // Use useEffect to navigate when necessary context updates have taken effect
  useEffect(() => {
    if (!loading && context.token !== "") {
      if (context.QID < 20 || context.QA === 0) {
        navigation.navigate("Question");
      } else {
        // navigation.navigate("Home", { refresh: true });
        navigation.navigate("Home", { refresh: Date.now() });
      }
    }
  }, [loading]);

  useEffect(() => {
    // Add any other necessary setup or initialization logic for the Login screen here
    // Clear any previous data or states when the Login screen is loaded

    navigation.addListener("focus", () => {
      // Perform any necessary actions on screen focus
      // This code will execute whenever the Login screen comes into focus
      setEmail("");
      setPassword("");
      setError("");
      setSecureTextEntry(true);
    });

    return () => {
      // Cleanup code here
      navigation.removeListener("focus");
    };
  }, []);

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#F7F6CF" />
      </View>
    );
  }

  if (!loading && context.token === "") {
    return (
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        keyboardVerticalOffset={100}
        enabled
        style={styles.container}
      >
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          <View style={styles.panel}>
            <Image
              style={styles.logoImage}
              source={require("../assets/images/BFcircle.png")}
            />
            {error ? <Text style={styles.errorText}>{error}</Text> : null}
            <Text style={styles.textlabel}>Email</Text>
            <View style={styles.row}>
              <TextInput
                style={styles.textinput}
                placeholder="Email"
                value={email}
                onChangeText={setEmail}
              />
            </View>
            <Text style={styles.textlabel}>Password</Text>
            <View style={styles.row}>
              <View style={styles.inputContainer}>
                <TextInput
                  style={styles.textinput}
                  placeholder="Password"
                  secureTextEntry={secureTextEntry}
                  value={password}
                  onChangeText={setPassword}
                  onSubmitEditing={handleLogin} // Called when Enter key is pressed
                />
                <TouchableOpacity
                  style={styles.eyeIcon}
                  onPress={toggleSecureTextEntry}
                >
                  {secureTextEntry ? (
                    <Feather name="eye-off" color="grey" size={20} />
                  ) : (
                    <Feather name="eye" color="black" size={20} />
                  )}
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.buttonbar}>
              <TouchableOpacity
                style={[styles.button, styles.buttonpink]}
                onPress={() => navigation.navigate("SignUp")}
              >
                <Text style={styles.text}>Sign Up</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.button, styles.buttonblue]}
                onPress={handleLogin}
              >
                <Text style={styles.textwhite}>Login</Text>
              </TouchableOpacity>
            </View>

            <TouchableOpacity
              style={[styles.button, styles.question]}
              onPress={() =>
                navigation.navigate("PasswordReset", { backRoute: "Login" })
              }
            >
              <Text style={styles.text}>Forgot password?</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
        <StatusBar style="auto" />
      </KeyboardAvoidingView>
    );
  }
};

export default LoginScreen;
