import { useCallback, useEffect, useState, useRef } from "react";
import * as SplashScreen from "expo-splash-screen";

import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";

import {
  StyleSheet,
  View,
  Button,
  Image,
  Text,
  SafeAreaView,
  Alert,
  Platform,
} from "react-native";
import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import Constants from "expo-constants";

// Fonts
import * as Font from "expo-font";
// import { Sofia_400Regular } from '@expo-google-fonts/sofia';
// import { HappyMonkey_400Regular } from '@expo-google-fonts/happy-monkey';
// import { JosefinSans_400Regular } from '@expo-google-fonts/josefin-sans';
import GlobalState from "./Context/GlobalState";
import Header from "./components/Header";
import HeaderBase from "./components/HeaderBase";
// Screens
import LoginScreen from "./screens/LoginScreen";
import SignUpScreen from "./screens/SignUpScreen";
import PasswordReset from "./screens/PasswordResetScreen";
import QuestionScreen from "./screens/QuestionScreen";
import HomeScreen from "./screens/HomeScreen";
// Lazy Load
//const FriendScreen = React.lazy(() => import("./screens/FriendsScreen"));
import FriendScreen from "./screens/FriendsScreen";
import FriendProfile from "./screens/FriendProfileScreen";
import ChatScreen from "./screens/ChatScreen";
import ProfileScreen from "./screens/ProfileScreen";
import EmailScreen from "./screens/EmailChangeScreen";
import AvatarScreen from "./screens/AvatarScreen";
import TrialScreen from "./screens/TrialScreen";

// Side menu
import CustomDrawer from "./components/CustomDrawer";
import colors from "./assets/colors";
import GamesScreen from "./screens/GamesScreen";

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const Drawer = createDrawerNavigator();

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);
  const [expoPushToken, setExpoPushToken] = useState("");
  const [notification, setNotification] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();

  useEffect(() => {
    if (Platform.OS === "web") return; // Notification on web is not supported.
    registerForPushNotificationsAsync().then((token) =>
      setExpoPushToken(token)
    );

    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification);
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        console.log(response);
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current
      );
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  useEffect(() => {
    async function prepare() {
      try {
        // Keep the splash screen visible while we fetch resources
        await SplashScreen.preventAutoHideAsync();
        // Pre-load fonts, make any API calls you need to do here
        //await Font.loadAsync(Sofia_400Regular);
        await Font.loadAsync({
          Sofia: require("./assets/fonts/Sofia-Regular.ttf"),
          HappyMonkey_400Regular: require("./assets/fonts/HappyMonkey-Regular.ttf"),
          JosefinSans_400Regular: require("./assets/fonts/JosefinSans-Regular.ttf"),
        });

        // Artificially delay for two seconds to simulate a slow loading
        // experience. Please remove this if you copy and paste the code!
        // await new Promise(resolve => setTimeout(resolve, 2000));
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }

    prepare();
  }, []);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <GlobalState>
      <SafeAreaView style={{ flex: 1 }}>
        <NavigationContainer onReady={onLayoutRootView}>
          <Drawer.Navigator
            drawerContent={(props) => <CustomDrawer {...props} />}
            screenOptions={{
              gestureEnabled: false,
              swipeEnabled: false,
              // header: props => <Header {...props} />
            }}
          >
            {/* <Drawer.Screen
              name="Trial"
              component={TrialScreen}
              options={{ header: (props) => <HeaderBase {...props} /> }}
            /> */}
            {/* <Drawer.Screen
              name="Games"
              component={GamesScreen}
              options={{ header: (props) => <Header {...props} /> }}
            /> */}
            <Drawer.Screen
              name="Login"
              component={LoginScreen}
              options={{ header: (props) => <HeaderBase {...props} /> }}
            />
            <Drawer.Screen
              name="PasswordReset"
              component={PasswordReset}
              options={{ header: (props) => <HeaderBase {...props} /> }}
            />
            <Drawer.Screen
              name="SignUp"
              component={SignUpScreen}
              options={{
                drawerLabel: "Befriender - Account Creation",
                header: (props) => <HeaderBase {...props} />,
              }}
            />
            <Drawer.Screen
              name="Home"
              component={HomeScreen}
              options={{
                drawerLabel: "Befriender - Home",
                header: (props) => <Header {...props} />,
              }}
            />
            <Drawer.Screen
              name="Friends"
              component={FriendScreen}
              options={{
                drawerLabel: "Befriender - Friends",
                header: (props) => <Header {...props} />,
              }}
            />
            <Drawer.Screen
              name="FriendProfile"
              component={FriendProfile}
              options={{
                header: (props) => <Header {...props} />,
              }}
            />
            <Drawer.Screen
              name="Question"
              component={QuestionScreen}
              options={{
                drawerLabel: "Daily Question",
                header: (props) => <Header {...props} />,
              }}
            />
            <Drawer.Screen
              name="Profile"
              component={ProfileScreen}
              options={{ header: (props) => <Header {...props} /> }}
            />
            <Drawer.Screen
              name="ProfilePassword"
              component={PasswordReset}
              options={{ header: (props) => <Header {...props} /> }}
            />
            <Drawer.Screen
              name="ProfileEmail"
              component={EmailScreen}
              options={{ header: (props) => <Header {...props} /> }}
            />
            <Drawer.Screen
              name="Avatar"
              component={AvatarScreen}
              options={{ header: (props) => <Header {...props} /> }}
            />
            <Drawer.Screen
              name="Chat"
              component={ChatScreen}
              options={{
                drawerLabel: "Chat",
                header: (props) => <Header {...props} />,
              }}
            />
          </Drawer.Navigator>
        </NavigationContainer>
      </SafeAreaView>
    </GlobalState>
  );
}
async function schedulePushNotification() {
  await Notifications.scheduleNotificationAsync({
    content: {
      title: "You've got mail! 📬",
      body: "Here is the notification body",
      data: { data: "goes here" },
    },
    trigger: { seconds: 2 },
  });
}

async function registerForPushNotificationsAsync() {
  let token;

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    // Learn more about projectId:
    // https://docs.expo.dev/push-notifications/push-notifications-setup/#configure-projectid
    token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: Constants.expoConfig.extra.eas.projectId,
      })
    ).data;
    console.log(token);
  } else {
    alert("Must use physical device for Push Notifications");
  }

  return token;
}
