import { BAPI_URL } from "./constants"; // Import the base URL of your BAPI

// Function to handle API errors
const handleAPIError = (error) => {
  console.error("API Error:", error);
  throw error;
};

// Function to make a POST request to the sign-up endpoint
export const signUp = async (username, email) => {
  try {
    const response = await fetch(`${BAPI_URL}/register/create.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, email }),
    });

    if (!response.ok) {
      throw new Error("Sign-up failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to make a POST request to the verify endpoint
export const verifyPin = async (username, email, txtCode, password) => {
  try {
    const response = await fetch(`${BAPI_URL}/register/verify.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, email, txtCode, password }),
    });

    if (!response.ok) {
      throw new Error("verify failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to make a POST request to the login endpoint
export const login = async (email, password) => {
  try {
    const response = await fetch(`${BAPI_URL}/login/login.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

export const logout = async (token) => {
  try {
    const response = await fetch(`${BAPI_URL}/logout/logout.php`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to log out");
    }

    const data = await response.json();
    if (data.error) {
      throw new Error(data.message);
    }

    // Perform any additional cleanup or tasks after successful logout
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to update the password
export const resetPassword = async (email) => {
  try {
    const response = await fetch(`${BAPI_URL}/register/forgot.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error("reset failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to update user Alias.
export const updateAlias = async (token, username) => {
  try {
    const response = await fetch(`${BAPI_URL}/user/updateAlias.php`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ username }),
    });

    if (!response.ok) {
      throw new Error("update failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to update user Alias.
export const updateEmail = async (token, currentEmail, newEmail) => {
  try {
    const response = await fetch(`${BAPI_URL}/user/update.php`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ currentEmail, newEmail }),
    });

    if (!response.ok) {
      throw new Error("update failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to update user Alias.
export const verifyEmail = async (token, currentEmail, newEmail, txtCode) => {
  try {
    const response = await fetch(`${BAPI_URL}/user/verifyUpdate.php`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ currentEmail, newEmail, txtCode }),
    });

    if (!response.ok) {
      throw new Error("update failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to update user Alias.
export const inviteFriend = async (token, email) => {
  try {
    const response = await fetch(`${BAPI_URL}/friends/invite.php`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error("update failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

export const setFriend = async (token, groupToken, status) => {
  try {
    const response = await fetch(`${BAPI_URL}/friends/setFriends.php`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ groupToken, status }),
    });

    if (!response.ok) {
      throw new Error("update failed. Please try again."); // Throw an error with a specific message
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to make a GET request to fetch the user's profile
export const getGroupExpire = async (token) => {
  try {
    const response = await fetch(`${BAPI_URL}/group/expire.php`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch expiry date");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to make a request to join a group.
export const requestGroup = async (token) => {
  try {
    const response = await fetch(`${BAPI_URL}/group/setGroup.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to join group");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// Function to make a GET request to fetch the daily question
export const getDailyQuestion = async (token) => {
  try {
    const response = await fetch(`${BAPI_URL}/questions/questions.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch daily question");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

export const getFriendAvatars = async (token) => {
  try {
    const response = await fetch(`${BAPI_URL}/friends/getFriends.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get group members");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

export const getFriendProfile = async (token, groupToken) => {
  try {
    const response = await fetch(`${BAPI_URL}/friends/getProfile.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ groupToken }),
    });

    if (!response.ok) {
      throw new Error("Failed to get member");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

export const getGroupAvatars = async (token) => {
  try {
    const response = await fetch(`${BAPI_URL}/group/group.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to get group members");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

// bapi.js

export const sendMessage = async (token, groupToken, MSGBody) => {
  try {
    const response = await fetch(`${BAPI_URL}/chat/sendMsg.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ groupToken, MSGBody }),
    });

    if (!response.ok) {
      throw new Error("Failed to send message");
    }

    // Handle the response as needed
    const data = await response.json();
    console.log("Message sent successfully:", data);
  } catch (error) {
    throw new Error("Error sending message: " + error.message);
  }
};

export const authChat = async (token, groupToken) => {
  try {
    const response = await fetch(`${BAPI_URL}/chat/chatAuth.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ groupToken }), // Include the groupToken in the request body as JSON
    });

    if (!response.ok) {
      throw new Error("Failed to authenticate chat");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Error authenticating chat: ${error.message}`);
  }
};

// Function to make a POST request to submit the user's answer to a question
export const submitAnswer = async (token, questionId, answer) => {
  try {
    const response = await fetch(`${BAPI_URL}/questions/answer.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ questionId, answer }),
    });

    if (!response.ok) {
      throw new Error("Failed to submit answer");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};

export const generateAvatar = async (
  token,
  bodyIndex,
  eyesIndex,
  mouthIndex,
  hatIndex,
  roomIndex
) => {
  try {
    const response = await fetch(`${BAPI_URL}/avatar/avatarimg.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        bodyIndex,
        eyesIndex,
        mouthIndex,
        hatIndex,
        roomIndex,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to generate avatar");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    handleAPIError(error);
  }
};
