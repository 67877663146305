import React, { useState, useEffect, useContext, useRef } from "react";
import {
  View,
  Text,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import { styles } from "../assets/Style";
import { AVATAR_URL, BAPI_URL } from "../constants";
import { sendMessage, authChat } from "../bapi";
import EventSource from "react-native-sse";
import Context from "../Context/context";
import { useNavigation, useIsFocused } from "@react-navigation/native";

const ChatComponent = ({ groupToken, lastMessageIdRef }) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [chatAuthToken, setchatAuthToken] = useState("");
  const eventSourceRef = useRef(null);
  const scrollViewRef = useRef(null);
  const context = useContext(Context);
  const { token, Alias, Avatar } = context;
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      setMessages([]);
      lastMessageIdRef.current = 0;
      authChat(token, groupToken)
        .then((response) => {
          setchatAuthToken(response.chatAuthToken);
          startEventSource(response.chatAuthToken);
        })
        .catch((error) => {
          console.error("Error authenticating chat:", error);
        });

      return () => {
        stopEventSource();
      };
    }
  }, [groupToken, isFocused]);

  // If user logs out, stop polling.
  useEffect(() => {
    if (token === "") {
      stopEventSource();
    }
  }, [token]);

  // Stop polling when you leave the chat.
  // You left the room, you can't hear the conversation.
  useEffect(() => {
    if (!isFocused) {
      stopEventSource();
    }
  }, [isFocused]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated: true });
    }
  }, [messages]);

  const startEventSource = (chatAuthToken) => {
    const url = `${BAPI_URL}/chat/getMsg.php?token=${encodeURIComponent(
      chatAuthToken
    )}&lastMessageId=${lastMessageIdRef.current}`;

    const headers = {
      Authorization: `Bearer ${chatAuthToken}`,
      "Content-Type": "application/json",
    };

    const eventSource = new EventSource(url, { headers });
    eventSource.addEventListener("message", (event) => {
      const responseData = event.data.split("\n");
      const newMessages = responseData.map((line) => {
        try {
          return JSON.parse(line);
        } catch (error) {
          console.error("Error parsing message:", line);
          return null;
        }
      });

      // Filter out any null or invalid messages
      const filteredMessages = newMessages.filter(
        (message) => message !== null
      );

      setMessages((prevMessages) => [...prevMessages, ...filteredMessages]);

      if (filteredMessages.length > 0) {
        lastMessageIdRef.current =
          filteredMessages[filteredMessages.length - 1].id;
      }

      // Request an update by closing and reopening the SSE connection
      eventSource.close();
      startEventSource(chatAuthToken);
    });

    eventSource.addEventListener("error", (error) => {
      console.error("SSE error:", error);
      // Handle SSE error here
    });

    // Store the eventSource object in the ref for later access
    eventSourceRef.current = eventSource;
  };

  const stopEventSource = () => {
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }
  };

  const handleSend = async () => {
    if (inputText.trim() === "") {
      return;
    }
    const newMessage = {
      id: lastMessageIdRef.current + 1, // Assign the next ID based on the current last message ID
      sender: Alias,
      image: Avatar,
      time: getCurrentTime(),
      content: inputText,
    };

    try {
      setInputText("");
      await sendMessage(token, groupToken, newMessage);

      // Request an update by restarting the SSE
      stopEventSource();
      startEventSource(chatAuthToken);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const getCurrentTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours().toString().padStart(2, "0");
    const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    const time = `${hours}:${minutes}`;
    return time;
  };

  return (
    <>
      <ScrollView
        ref={scrollViewRef}
        style={styles.ChatView}
        contentContainerStyle={styles.ChatContent}
      >
        {messages.map((message) => (
          <View style={styles.MsgContainer} key={message.id}>
            <Image
              source={{ uri: `${AVATAR_URL}${message.image}.png` }}
              defaultSource={require("../assets/images/avatar/Avatar.png")}
              style={styles.MsgImg}
            />
            <View style={{ flex: 1 }}>
              <View style={{ flexDirection: "row", flex: 1 }}>
                <Text style={styles.MsgTag}>{message.sender}</Text>
                <Text style={styles.MsgTime}>{message.time}</Text>
              </View>
              <Text style={styles.text}>{message.content}</Text>
            </View>
          </View>
        ))}
      </ScrollView>
      <View style={styles.chatBottom}>
        <TextInput
          style={[styles.textinput, { marginRight: 8, marginBottom: 0 }]}
          placeholder="Enter Message"
          value={inputText}
          onChangeText={setInputText}
          onSubmitEditing={handleSend} // Called when Enter key is pressed
        ></TextInput>
        <TouchableOpacity
          style={styles.gamesButtonContainer}
          onPress={handleSend}
        >
          <Text style={styles.gamesButtonText}>Send</Text>
        </TouchableOpacity>
      </View>
    </>
  );
};

export default ChatComponent;
