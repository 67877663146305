import React, { useContext, useState } from "react";
import {
  View,
  Text,
  TextInput,
  ScrollView,
  TouchableOpacity,
  Image,
  KeyboardAvoidingView,
  Platform,
  Alert, // Import Alert for displaying messages
} from "react-native";
import { styles } from "../assets/Style";
import { AVATAR_URL } from "../constants";
import { updateAlias } from "../bapi";
import Context from "../Context/context";
import { useNavigation, useRoute } from "@react-navigation/native";
import EmailChangeScreen from "./EmailChangeScreen";
import colors from "../assets/colors";

const ProfileScreen = () => {
  const context = useContext(Context);
  const navigation = useNavigation();
  const route = useRoute();

  const [newAlias, setNewAlias] = useState(context.Alias); // State to store the new username

  const AvatarImage = () => {
    var AvatarURL = `${AVATAR_URL}` + context.Avatar + ".png";

    return (
      <Image
        source={{
          uri: AvatarURL,
        }}
        style={[styles.avatarOutline]}
      />
    );
  };

  const handleResetPassword = () => {
    navigation.navigate("ProfilePassword", {
      initialEmail: context.Email,
      backRoute: route.name,
    });
  };

  const handleSave = () => {
    // Call the updateAlias function with the newAlias
    updateAlias(context.token, newAlias)
      .then((response) => {
        if (response.error) {
          // Handle error, display an alert, or take appropriate action
          Alert.alert("Error", response.message);
        } else {
          // Alias updated successfully
          Alert.alert("Success", "Alias updated successfully");
          // You can also update the context or perform other actions as needed
          context.setAlias(newAlias);
        }
      })
      .catch((error) => {
        // Handle any unexpected errors
        Alert.alert("Error", "An error occurred while updating the alias");
      });
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={100}
      enabled
      style={styles.Bodyscroll}
    >
      <ScrollView style={styles.Bodyscroll}>
        <View style={styles.panel}>
          <View
            style={[styles.compatibilityCircle, { borderColor: colors.bfc5 }]}
          >
            <Text style={[styles.text, { fontSize: 16, textAlign: "center" }]}>
              Alpha
            </Text>
            <Text style={[{ fontSize: 10 }]}>Member</Text>
          </View>
          <TouchableOpacity
            onPress={() => navigation.navigate("Avatar")}
            style={{ alignItems: "center" }}
          >
            {AvatarImage()}
            <View style={styles.avatarTag}>
              <Text style={styles.textwhite}>{context.Alias}</Text>
            </View>
            <Text style={styles.text}>Edit Avatar</Text>
          </TouchableOpacity>

          <Text style={styles.textlabel}>Display Name</Text>
          <View style={{ flexDirection: "row" }}>
            <TextInput
              style={styles.textinput}
              placeholder={context.Alias}
              value={newAlias}
              onChangeText={setNewAlias} // Update the newAlias state when the text input changes
            ></TextInput>
            <TouchableOpacity
              style={styles.button}
              onPress={handleSave} // Call handleSave when the "Save" button is pressed
            >
              <Text style={styles.text}>Save</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.textlabel}>Email</Text>
          <TouchableOpacity
            style={[styles.button, styles.buttonpink]}
            onPress={() =>
              navigation.navigate("ProfileEmail", {
                backRoute: route.name,
              })
            }
          >
            <Text style={styles.text}>Change Email</Text>
          </TouchableOpacity>

          <Text style={styles.textlabel}>Password</Text>
          <TouchableOpacity
            style={[styles.button, styles.buttonblue]}
            onPress={() => handleResetPassword()}
          >
            <Text style={styles.textwhite}>Change Password</Text>
          </TouchableOpacity>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default ProfileScreen;
