import React, { useState, useContext } from "react";
import { useFocusEffect } from "@react-navigation/native";
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { styles } from "../assets/Style";
import { getDailyQuestion, submitAnswer } from "../bapi";
import Context from "../Context/context";
import RemainingClock from "../components/RemainingTime"; // Import the RemainingTime component

const QuestionScreen = ({ navigation }) => {
  const context = useContext(Context);
  //const token = context.token; // Access the token from the context
  const QA = context.QA;
  const QID = context.QID;
  //const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [question, setQuestion] = useState("");
  const [numberAnswers, setNumberAnswers] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);

  useFocusEffect(
    React.useCallback(() => {
      if (context.token) {
        fetchQuestion(context.token);
      }

      return () => {
        // Cleanup code if needed
      };
    }, [context.token])
  );

  const fetchQuestion = async (token) => {
    setLoading(true);
    try {
      const data = await getDailyQuestion(token);
      if (data.error) {
        // Handle the error and display a message to the user
        alert("Error: " + data.message);
      } else {
        context.setQID(data.questionId);
        context.setQA(data.questionAnswered);
        setQuestion(data.question);
        setNumberAnswers(data.numberAnswers);
        setAnswers(data.answers);
        setRemainingTime(data.RemainingTime);
      }
    } catch (error) {
      // Handle the fetch error and display a message to the user
      alert("Error: " + error.message);
      console.error("ERROR FOUND: ", error);
    } finally {
      setLoading(false);
    }
  };

  const answerQuestion = async (choice) => {
    setLoading(true);
    try {
      const data = await submitAnswer(context.token, QID, choice);
      if (data.error) {
        // Handle the error and display a message to the user
        alert("Error: " + data.message);
      } else {
        // Process the response data as needed
        await fetchQuestion(context.token);
      }
    } catch (error) {
      // Handle the fetch error and display a message to the user
      alert("Error: " + error.message);
      console.error("ERROR FOUND: ", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#F7F6CF" />
      </View>
    );
  }

  if (QA === 0) {
    const showHeader = () => {
      const FirstQuestions = 20;
      if (QID <= FirstQuestions) {
        return (
          <View style={styles.Header}>
            <Text style={styles.HeaderFont}>
              Question {QID} of {FirstQuestions}
            </Text>
          </View>
        );
      } else {
        return (
          <View style={styles.Header}>
            <Text style={styles.HeaderFont}>Daily Question</Text>
          </View>
        );
      }
    };

    const showAnswers = () => {
      return answers.slice(0, numberAnswers).map((answer, index) => (
        <TouchableOpacity
          key={index}
          style={styles.answers}
          onPress={() => answerQuestion(index)}
        >
          <Text style={styles.text}>{answer}</Text>
        </TouchableOpacity>
      ));
    };

    return (
      <ScrollView style={styles.Bodyscroll}>
        <View style={styles.Body}>
          <View style={styles.questionPanel}>
            {showHeader()}
            <View style={styles.question}>
              <Text style={styles.text}>{question}</Text>
            </View>
            <View style={styles.hr}></View>
            {/* Buttons */}
            {showAnswers()}
          </View>
        </View>
      </ScrollView>
    );
  }

  // Daily Question is answered
  if (QA === 1) {
    return (
      <ScrollView style={styles.Bodyscroll}>
        <View style={styles.Body}>
          <View style={styles.questionPanel}>
            <View style={styles.Header}>
              <Text style={styles.HeaderFont}>Questions Complete</Text>
            </View>
            <View style={styles.question}>
              <Text style={[styles.text, { alignSelf: "center" }]}>
                A New Question Everyday!
              </Text>
            </View>
            <RemainingClock remainingTime={remainingTime} />
            {/* Buttons */}
            <View style={styles.hr}></View>
            <View style={styles.buttonbar}>
              <TouchableOpacity
                style={[styles.button, styles.buttonpink]}
                onPress={() =>
                  navigation.navigate("Home", { refresh: Date.now() })
                }
              >
                <Text style={styles.text}>Friendship Hub</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  // Handle other scenarios or return null if needed
  return null;
};

export default QuestionScreen;
